import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import DarkModeToggle from './dark-mode-toggle';

const Menu = ({ location }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const blogPath = `${__PATH_PREFIX__}/blog/`;
  const techPath = `${__PATH_PREFIX__}/tech/`;

  return (
    <Nav>
      <Link to={`/`}>
        <MenuItem active={location.pathname === rootPath}>Home</MenuItem>
      </Link>
      <Link to={`/blog/`}>
        <MenuItem active={location.pathname.includes(blogPath)}>Blog</MenuItem>
      </Link>
      <Link to={`/tech/`}>
        <MenuItem active={location.pathname.includes(techPath)}>Tech</MenuItem>
      </Link>
    </Nav>
  );
};

const Nav = styled.nav`
  marginbottom: 40;
  // border-top: 1px solid ${props => props.theme.primary};
  // border-bottom: 1px solid ${props => props.theme.primary};

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
`;

const MenuItem = styled.span`
  text-decoration: none;
  font-size: 22px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  margin: 10px 30px 10px 0px;
  padding: 10px 0px;

  border-top: ${props =>
    props.active ? `1px solid ${props.theme.primary}` : ''};
  border-bottom: ${props =>
    props.active ? `1px solid ${props.theme.primary}` : ''};
`;

export default Menu;
