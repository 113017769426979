import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const Header = () => (
  <header>
    <HeaderWrapper onClick={() => navigate('/')}>
      <Title>Chris Kalmar</Title>
      <Slogan>Web Development &nbsp; | &nbsp; Graphic Design</Slogan>
    </HeaderWrapper>
  </header>
);

const HeaderWrapper = styled.div`
  font-family: Montserrat, sans-serif;
  margin-bottom: 40px;
  cursor: pointer;
  opacity: 0.7;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */

  &:hover {
    opacity: 1;
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 100;
  font-size: 3rem;

  @media (max-width: 680px) {
    font-size: 2.5rem;
  }
  @media (max-width: 320px) {
    font-size: 2rem;
  }
`;

const Slogan = styled.div`
  font-weight: 100;
  font-size: 1.5rem;

  @media (max-width: 680px) {
    font-size: 1rem;
  }
  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export default Header;
