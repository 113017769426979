import React from 'react';
import styled from 'styled-components';

const Social = () => (
  <>
    <SocialLink href="https://twitter.com/chriskalmar" title="Twitter">
      <i class="fab fa-twitter-square"></i>
    </SocialLink>
    <SocialLink href="https://github.com/chriskalmar" title="GitHub">
      <i class="fab fa-github-square"></i>
    </SocialLink>
    <SocialLink
      href="https://www.linkedin.com/in/chris-kalmar/"
      title="LinkedIn"
    >
      <i class="fab fa-linkedin"></i>
    </SocialLink>
    <SocialLink href="https://dev.to/chriskalmar" title="Dev.to">
      <i class="fab fa-dev"></i>
    </SocialLink>
    {/* <SocialLink href="https://hashnode.com/@chriskalmar" title="Hashnode">
                <i class="fab fa-twitter"></i>
              </SocialLink> */}
  </>
);

const SocialLink = styled.a`
  text-decoration: none !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 135px;
  box-shadow: none;

  color: ${props => props.theme.primary};
  font-size: 32px;

  &:hover {
    color: ${props => props.theme.primaryLight};
  }
`;

export default Social;
