import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeManagerContext } from 'gatsby-styled-components-dark-mode';

const DarkModeToggle = props => {
  const themeContext = useContext(ThemeManagerContext);

  return (
    <DarkModeWrapper onClick={() => themeContext.toggleDark()}>
      <i className={themeContext.isDark ? 'fas fa-sun' : 'fas fa-moon'}></i>
    </DarkModeWrapper>
  );
};

export default DarkModeToggle;

const DarkModeWrapper = styled.div`
  position: absolute;
  right: 0;
  padding: 10px 20px;
  font-size: 20px;
  color: ${props => props.theme.primaryLight};
`;
