import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled, { createGlobalStyle, withTheme } from 'styled-components';

import { rhythm, scale } from '../utils/typography';
import Social from './social';
import DarkModeToggle from './dark-mode-toggle';
import Menu from './menu';
import Header from './header';

const Layout = props => {
  const { location, title, children } = props;
  const rootPath = `${__PATH_PREFIX__}/`;
  const blogPath = `${__PATH_PREFIX__}/blog/`;
  let header;

  // if (location.pathname === rootPath) {
  //   header = '';
  // } else if (location.pathname === blogPath) {
  //   header = (
  //     <h1
  //       style={{
  //         ...scale(1.5),
  //         marginBottom: rhythm(1.5),
  //         marginTop: 0,
  //       }}
  //     >
  //       <Link
  //         style={{
  //           boxShadow: `none`,
  //           textDecoration: `none`,
  //           color: `inherit`,
  //         }}
  //         to={location.pathname === blogPath ? `/blog/` : `/`}
  //       >
  //         {title}
  //       </Link>
  //     </h1>
  //   );
  // } else {
  //   header = (
  //     <h3
  //       style={{
  //         fontFamily: `Montserrat, sans-serif`,
  //         marginTop: 0,
  //       }}
  //     >
  //       <Link
  //         style={{
  //           boxShadow: `none`,
  //           textDecoration: `none`,
  //           color: `inherit`,
  //         }}
  //         to={`/blog/`}
  //       >
  //         {title}
  //       </Link>
  //     </h3>
  //   );
  // }
  return (
    <>
      <GlobalStyle theme={props.theme} />

      <DarkModeToggle />

      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          {/* <header>{header}</header> */}
          <Header />
          <Menu location={location} />
          <main>{children}</main>
        </div>
        <Footer>
          <div
            style={{
              marginBottom: rhythm(1),
            }}
          >
            <Social />
          </div>
          &copy; {new Date().getFullYear()}, Chris Kalmar. Built with
          {` `}
          <a href="https://www.gatsbyjs.org" rel="nofollow">
            Gatsby
          </a>
        </Footer>
      </Wrapper>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.text};
    transition: all 0.25s linear;
  }

  a {
    color: ${props => props.theme.primary};
    // box-shadow: 0 1px 0 0 currentColor;
    box-shadow: none;
  }
  a:hover {
    color: ${props => props.theme.primaryLight};
  }

  blockquote {
    color: ${props => props.theme.text};
    opacity: 0.8;
    border-left-color: ${props => props.theme.primary};
    font-size: 1.5rem;
  }

  p code {
    color: ${props => props.theme.textInverted};
    border: 1px solid ${props => props.theme.primaryLight};
    border-radius: 5px;
    padding: 4px;
    background: ${props => props.theme.primaryLight};
    opacity: 0.9;
  }

  ::-moz-selection {
    color: ${props => props.theme.background};
    background: ${props => props.theme.primary};
  }

  ::selection {
    color: ${props => props.theme.background};
    background: ${props => props.theme.primary};
  }

  hr {
    background-color: ${props => props.theme.primaryLight};
    opacity: 0.2;
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
`;

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
  font-family: Montserrat, sans-serif;
`;

export default Layout;
